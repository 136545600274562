
import {
  defineComponent,
  ref,
  onMounted,
  onActivated,
  onUpdated,
  computed,
} from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdwon from "./FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiSettlementCash } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import mixin from "@/mixins";
import {
  commonChangeFilterAddDate,
  commonExportFile,
  formatDate,
  setModuleBCN,
  settlementAddDate,
  settlementLastMonthDate,
  settlementLastMonthDateString,
  settlementLastWeekDate,
  settlementLastWeekDateString,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import {
  FilterOption,
  SortOption,
  TaggingItem,
} from "@/core/directive/interface/common";
import { checkPermissionFlag } from "@/directives/permission";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import {
  settlementCashInternalBankBalanceTable,
  SettlementCashInternalBankBalanceTable,
} from "@/core/directive/interface/settlementCash";
import UpdateTableItem from "./UpdateTableItem.vue";
import { getInternalCashPoolStatus } from "@/core/directive/function/settlementCash";
import { InternalCashPoolStatus } from "@/core/directive/type/settlementCash";
import AddPrepayment from "./AddPrepayment.vue";

export default defineComponent({
  name: "settlement-cash-internal-bank-balance",
  components: {
    MBDatatable,
    FilterDropdwon,
    UpdateTableItem,
    AddPrepayment,
    PermissionCommon,
  },
  setup() {
    const { t } = useI18n();
    const { showServerErrorMsg } = mixin();
    const route = useRoute();

    const loading = ref(false);
    const filterRef = ref();
    const tableData = ref<Array<SettlementCashInternalBankBalanceTable>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const disabledExportSelected = ref(false);
    const disabledExportAll = ref(false);
    const disabledExportLastWeek = ref(false);
    const disabledExportLastMonth = ref(false);
    const checkedInvoice = ref<Array<number>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "created_at", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);
    const tableHeader = ref([
      {
        name: t("settlementCash.id"),
        key: "id",
        sortable: false,
      },
      {
        name: t("settlementCash.debtor"),
        key: "debtor",
        sortable: false,
      },
      {
        name: t("settlementCash.allowPayment"),
        key: "allow_payment",
        sortable: false,
      },
      {
        name: t("settlementCash.currency"),
        key: "currency",
        sortable: false,
      },
      {
        name: t("settlementCash.balance"),
        key: "balance",
        sortable: false,
        align: "right",
      },
      {
        name: t("common.creationDate"),
        key: "created_at",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      scource: "",
      scourceOptions: [] as TaggingItem[],
      filter_date: "invoice_date",
      itemData: Object.assign({}, settlementCashInternalBankBalanceTable),
      paymentGatewayOptions: [] as TaggingItem[],
      paymentGatewayMap: new Map([]),
      allowPaymentsOptions: [] as TaggingItem[],
    });

    const getTaggingData = async () => {
      // , "settlement_cash_status"
      const { data } = await ApiBase.getTaggingData({
        short_key: ["payment_gateway", "internal_bank_allow_payments"],
      });
      if (data.code == 0) {
        options.value.paymentGatewayOptions = data.data.payment_gateway.items;
        data.data.payment_gateway.items.map((item) => {
          options.value.paymentGatewayMap.set(item.value, item.label);
        });
        options.value.allowPaymentsOptions =
          data.data.internal_bank_allow_payments.items;
      }
    };

    const lastWeekFilter = computed(() => {
      const commonFilter = filterOptions.value;
      const resultFilter = commonFilter.filter((item) => {
        return !item.field.match(options.value.filter_date);
      });
      return {
        ...setCommonFilter.value,
        filter_group: [
          ...setOtherFilter.value,
          ...resultFilter,
          {
            field: options.value.filter_date,
            value: settlementLastWeekDateString({}),
            condition: "gteq",
          },
          {
            field: options.value.filter_date,
            value: settlementLastWeekDateString({
              isStart: false,
            }),
            condition: "lt",
          },
        ],
      };
    });

    const lastMonthFilter = computed(() => {
      const commonFilter = filterOptions.value;
      const resultFilter = commonFilter.filter((item) => {
        return !item.field.match(options.value.filter_date);
      });
      return {
        ...setCommonFilter.value,
        filter_group: [
          ...setOtherFilter.value,
          ...resultFilter,
          {
            field: options.value.filter_date,
            value: settlementLastMonthDateString({}),
            condition: "gteq",
          },
          {
            field: options.value.filter_date,
            value: settlementLastMonthDateString({
              isStart: false,
            }),
            condition: "lt",
          },
        ],
      };
    });

    const setOtherFilter = computed(() => {
      return [];
    });

    const setCommonFilter = computed(() => {
      return {
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        sort_orders: sortOptions.value,
        filter_group: [...filterOptions.value, ...setOtherFilter.value],
      };
    });

    const isCanExport = computed((): boolean => {
      return false;
      // return checkPermissionFlag({ auth: ["export"] });
    });

    const getList = async () => {
      loading.value = true;
      const { data } = await ApiSettlementCash.getInternalBankList(
        setCommonFilter.value
      );
      loading.value = false;
      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        tableData.value.forEach((item) => {
          item.downloadDisabled = false;
        });
        total.value = data.data.total;
      }
    };

    const onRowsSelectChange = (selection) => {
      let ids: number[] = [];
      selection.forEach((item) => {
        ids.push(item.id);
      });
      checkedInvoice.value = ids;
    };

    const resetData = () => {
      options.value.itemData = Object.assign(
        {},
        settlementCashInternalBankBalanceTable
      );
    };

    const editItem = (item) => {
      options.value.itemData = item;
    };

    const filterExportType = (type, flag = true) => {
      let params = {};
      if (type === "all") {
        if (flag) {
          disabledExportAll.value = true;
          params = {
            ...setCommonFilter.value,
          };
        } else {
          disabledExportAll.value = false;
        }
      } else if (type === "selected") {
        if (flag) {
          if (checkedInvoice.value.length > 0) {
            let arr = [...filterOptions.value, ...setOtherFilter.value];
            arr.push({
              field: "id",
              value: checkedInvoice.value,
              condition: "in",
            });
            params = {
              filter_group: arr,
            };
            disabledExportSelected.value = true;
          } else {
            return false;
          }
        } else {
          disabledExportSelected.value = false;
        }
      } else if (type === "lastWeek") {
        if (flag) {
          disabledExportLastWeek.value = true;
          params = {
            ...lastWeekFilter.value.filter_group,
          };
        } else {
          disabledExportLastWeek.value = false;
        }
      } else if (type === "lastMonth") {
        if (flag) {
          disabledExportLastMonth.value = true;
          params = {
            ...lastMonthFilter.value.filter_group,
          };
        } else {
          disabledExportLastMonth.value = false;
        }
      }
      if (flag) {
        return params;
      }
    };

    const filesExport = async (type = "all") => {
      let idParams = filterExportType(type);
      const data = await ApiSettlementCash.exportCash({
        ...idParams,
      });
      filterExportType(type, false);
      commonExportFile(data);
    };

    const fileExport = async (id, item) => {
      item.downloadDisabled = true;
      let arr = [...filterOptions.value, ...setOtherFilter.value];
      arr.push({
        field: "id",
        value: [id],
        condition: "in",
      });
      const data = await ApiSettlementCash.exportCash({
        filter_group: arr,
      });
      item.downloadDisabled = false;
      commonExportFile(data);
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (val[item] != "" && val[item] != null) {
            if (item.includes(options.value.filter_date)) {
              const isStart = item.includes("_start") ? true : false,
                date = isStart
                  ? commonChangeFilterAddDate(val[item])
                  : commonChangeFilterAddDate(val[item], 1);
              filterArr.push({
                field: options.value.filter_date,
                value: date,
                condition: isStart ? "gteq" : "lt",
              });
            } else if (item.includes("order_date")) {
              const isStart = item.includes("_start") ? true : false,
                date = isStart
                  ? commonChangeFilterAddDate(val[item])
                  : commonChangeFilterAddDate(val[item], 1);
              filterArr.push({
                field: "order_date",
                value: date,
                condition: isStart ? "gteq" : "lt",
              });
            } else {
              filterArr.push({
                field: item,
                value: val[item],
                condition: "eq",
              });
            }
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      updateList();
    };

    const handleFilterReset = () => {
      resetFilter();
      updateList();
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      updateList();
    };

    const updateList = () => {
      getList();
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      updateList();
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      updateList();
    };

    const debounceSearch = _.debounce(updateList, 1000);

    const searchItems = () => {
      debounceSearch();
    };

    const removeSearch = () => {
      search.value = "";
      updateList();
    };

    const init = () => {
      setModuleBCN(t, route);
      updateList();
      if (options.value.paymentGatewayOptions.length === 0) {
        getTaggingData();
      }
    };

    onMounted(() => {
      init();
      // if (store.getters.getAliveList.length === 0) {
      //   init();
      // }
    });

    onActivated(() => {
      init();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      formatDate,
      settlementLastWeekDate,
      settlementLastMonthDate,
      InternalCashPoolStatus,
      getInternalCashPoolStatus,
      loading,
      filterRef,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      options,
      isCanExport,
      disabledExportAll,
      disabledExportSelected,
      disabledExportLastWeek,
      disabledExportLastMonth,
      checkedInvoice,
      updateList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      onRowsSelectChange,
      resetData,
      editItem,
      filesExport,
      fileExport,
    };
  },
});
