/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-07-12 16:35:53
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-07-20 15:05:15
 * @FilePath: /settlement-frontend/src/core/directive/interface/settlementCash.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { NumberOrString } from "../type/common";

export const settlementCashInternalCashPoolTable: SettlementCashInternalCashPoolTable =
  {
    id: 0,
    created_at: "",
    service: "",
    type: "",
    sub_type: "",
    direction: 0,
    transaction_id: "",
    transaction_date: "",
    status: 0,
    order_number: "",
    currency: "",
    creditor_number: "",
    debtor_number: "",
    creditor_entity_id: 0,
    debtor_entity_id: 0,
    payable_amount: "",
    paid_amount: "",
    paid_currency: "",
    paid_time: "",
    external_transaction_platform: "",
    external_transaction_id: 0,
    debtor: "",
    creditor: "",
    status_label: "",
    prepayment_balance: 0,
    pay_bank_account_no: "",
    pay_bank_account_name: "",
    opening_bank: "",
    isChecked: false,
    downloadDisabled: false,
  };

export const settlementCashInternalBankBalanceTable: SettlementCashInternalBankBalanceTable =
  {
    id: 0,
    created_at: "",
    updated_at: "",
    deleted_at: "",
    created_uid: 0,
    updated_uid: 0,
    deleted_uid: 0,
    account_id: 0,
    currency: "",
    balance_amount: "",
    change_amount: "",
    reason: 0,
    note: "",
    allow_payments: "",
    transaction_cash_id: "",
    entity: "",
    allow_payments_label: "",
    isChecked: false,
    downloadDisabled: false,
  };

interface SettlementCashTable {
  id: NumberOrString;
  created_at: string;
  isChecked: boolean;
  downloadDisabled: boolean;
}

interface SettlementCashTableFilter {
  created_at_start?: string;
  created_at_end?: string;
}

export interface SettlementCashInternalCashPoolTable
  extends SettlementCashTable {
  service: string;
  type: string;
  sub_type: string;
  direction: number;
  transaction_id: string;
  transaction_date: string;
  status: number;
  order_number: string;
  currency: string;
  creditor_number: string;
  debtor_number: string;
  creditor_entity_id: NumberOrString;
  debtor_entity_id: NumberOrString;
  payable_amount: string;
  paid_amount: string;
  paid_currency: string;
  paid_time: string;
  external_transaction_platform: string;
  external_transaction_id: NumberOrString;
  debtor: string;
  creditor: string;
  status_label: string;
  prepayment_balance: number;
  pay_bank_account_no: string;
  pay_bank_account_name: string;
  opening_bank: string;
}

export interface SettlementCashInternalCashPoolTableFilter
  extends SettlementCashTableFilter {
  debtor_entity_id: string;
  creditor_entity_id: string;
  status: string;
  order_number: string;
}

export interface SettlementCashInternalBankBalanceTable
  extends SettlementCashTable {
  updated_at: string;
  deleted_at: string;
  created_uid: NumberOrString;
  updated_uid: NumberOrString;
  deleted_uid: NumberOrString;
  account_id: NumberOrString;
  currency: string;
  balance_amount: string;
  change_amount: string;
  reason: number;
  note: string;
  allow_payments: string;
  transaction_cash_id: string;
  entity: string;
  allow_payments_label: string;
}

export interface SettlementCashInternalBankBalanceTableFilter
  extends SettlementCashTableFilter {
  account_id: string;
  allow_payments: string;
  currency: string;
}
