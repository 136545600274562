
import { computed, defineComponent, onMounted, ref } from "vue";
import { ApiSettlementCash } from "@/core/api";
import mixin from "@/mixins";
import Swal from "sweetalert2/dist/sweetalert2.js";
import accounting from "accounting-js";
import { useI18n } from "vue-i18n";
import { CommonHtmlType } from "@/core/directive/type/common";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import {
  commonChangeDefaultDate,
  formatDate,
  modalShowListener,
} from "@/core/directive/function/common";
import { useRouter } from "vue-router";
import { InternalCashPoolStatus } from "@/core/directive/type/settlementCash";
import { getInternalCashPoolStatus } from "@/core/directive/function/settlementCash";
import { PaymentGateway } from "@/core/directive/type/transactionReport";

export default defineComponent({
  name: "settlement-cash-internal-bank-balance-update-item",
  emits: ["update-list", "reset-form"],
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const router = useRouter();
    const {
      showServerErrorMsg,
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
    } = mixin();

    const loading = ref(false);

    const updateInternalBankBalanceItemRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref({
      change_amount: 0,
      balance_amount: 0,
    });

    const options = ref({
      currency: [
        {
          label: "EUR",
          value: "EUR",
        },
        {
          label: "CNY",
          value: "CNY",
        },
      ],
      inputRange: [0, 0],
      inputIsShowRange: [false, false],
    });

    const rules = ref({
      change_amount: [
        {
          required: false,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (Number(value) > getChangeMax.value) {
                reject(t("common.isOutAdjustmentRange"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      balance_amount: [
        {
          required: false,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (Number(value) > getBalanceMax.value) {
                reject(t("common.isOutAdjustmentRange"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    // const getTaggingData = async () => {
    //   const { data } = await ApiBase.getTaggingData({
    //     short_key: ["sku_inventory_type"],
    //   });
    //   if (data.code == 0) {
    //     // options.value.inventory_type = data.data.sku_inventory_type.items;
    //   }
    // };

    const isEdit = computed(() => {
      return props.option.itemData.status === InternalCashPoolStatus.Unpaid;
    });

    const getMaxAmount = computed(() => {
      return accounting.toFixed(
        Number(props.option.itemData.change_amount) +
          Number(props.option.itemData.balance_amount),
        2
      );
    });

    const getChangeMax = computed(() => {
      return Number(
        accounting.toFixed(
          Number(getMaxAmount.value) - Number(formData.value.balance_amount),
          2
        )
      );
    });

    const getBalanceMax = computed(() => {
      return Number(
        accounting.toFixed(
          Number(getMaxAmount.value) - Number(formData.value.change_amount),
          2
        )
      );
    });

    const inputFocus = (e, type) => {
      options.value.inputIsShowRange[type] = true;
      options.value.inputRange[type] =
        type === 0 ? getChangeMax.value : getBalanceMax.value;
    };

    const inputBlur = (e, type) => {
      options.value.inputIsShowRange[type] = false;
      options.value.inputRange[type] =
        type === 0 ? getChangeMax.value : getBalanceMax.value;
    };

    const submitValidate = (callback) => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          // Swal.fire({
          //   text: t("settlementCash.submitTip"),
          //   icon: "warning",
          //   showCancelButton: true,
          //   buttonsStyling: false,
          //   confirmButtonText: t("common.yesClose"),
          //   cancelButtonText: t("common.noReturn"),
          //   customClass: {
          //     confirmButton: "btn btn-primary",
          //     cancelButton: "btn btn-danger",
          //   },
          // }).then(async (result) => {
          //   if (result.isConfirmed) {
          loading.value = true;
          const params = {
            id: props.option.itemData.id,
            change_amount: formData.value.change_amount,
            transfer_to: formData.value.balance_amount,
          };
          callback(params);
          //   }
          // });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submit = () => {
      submitValidate(async (params) => {
        const { data } = await ApiSettlementCash.transferInternalBank(params);
        loading.value = false;
        if (data.code === 0) {
          showFormSubmitSuccessMsg(() => {
            hideModal(updateInternalBankBalanceItemRef.value);
            emit("update-list");
          });
        } else {
          showServerErrorMsg(data);
        }
      });
    };

    const getFormInfo = async () => {
      for (let key in formData.value) {
        if (props.option.itemData[key]) {
          formData.value[key] = props.option.itemData[key];
        }
      }
      options.value.inputRange[0] = getChangeMax.value;
      options.value.inputRange[1] = getBalanceMax.value;
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const handleDiscard = () => {
      resetForm();
      hideModal(updateInternalBankBalanceItemRef.value);
    };

    onMounted(() => {
      modalShowListener(updateInternalBankBalanceItemRef.value, () => {
        setTimeout(() => {
          getFormInfo();
        }, 10);
      });
      modalHideListener(updateInternalBankBalanceItemRef.value, () => {
        resetForm();
      });
      //   getTaggingData();
    });

    return {
      t,
      formatDate,
      commonChangeDefaultDate,
      getInternalCashPoolStatus,
      InternalCashPoolStatus,
      PaymentGateway,
      props,
      updateInternalBankBalanceItemRef,
      formRef,
      loading,
      formData,
      rules,
      options,
      isEdit,
      inputFocus,
      inputBlur,
      handleDiscard,
      submit,
    };
  },
});
