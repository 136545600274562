
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { ApiBase, ApiDebtor } from "@/core/api";
import { TaggingItem } from "@/core/directive/interface/common";
import { DebtorOption } from "@/core/directive/interface/debtor";
import { SettlementCashInternalBankBalanceTableFilter } from "@/core/directive/interface/settlementCash";

export default defineComponent({
  name: "settlement-cash-internal-bank-balance-dropdown",
  emits: ["submit-filter", "reset-filter"],
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref<SettlementCashInternalBankBalanceTableFilter>({
      account_id: "",
      allow_payments: "",
      currency: "",
    });

    const options = ref({
      entityLoading: false,
      entityOptions: [] as DebtorOption[],
      custom3Options: [] as TaggingItem[],
      statusOptions: [] as TaggingItem[],
      paymentGatewayOptions: [] as TaggingItem[],
      currency: [
        {
          label: "EUR",
          value: "EUR",
        },
        {
          label: "CNY",
          value: "CNY",
        },
      ],
    });

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["internal_bank_allow_payments"],
      });
      if (data.code == 0) {
        // options.value.custom3Options = data.data.ocps_order_type.items;
        // options.value.statusOptions = data.data.settlement_invoice_status.items;
        // options.value.paymentGatewayOptions = data.data.payment_gateway.items;
      }
    };

    const getEntityData = async (value) => {
      options.value.entityLoading = true;
      const { data } = await ApiDebtor.searchDebtor({
        value: value,
      });
      options.value.entityLoading = false;
      if (data.code == 0) {
        options.value.entityOptions = data.data;
      }
    };

    const debounceEntitySearch = _.debounce(getEntityData, 1000);

    const searchEntityItems = (query: string) => {
      debounceEntitySearch(query);
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      // getTaggingData();
    });

    return {
      t,
      formData,
      formRef,
      options,
      searchEntityItems,
      submit,
      handleReset,
    };
  },
});
