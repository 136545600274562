
import { defineComponent, onMounted, ref } from "vue";
import { ApiDebtor, ApiSettlementCash } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import { CommonHtmlType } from "@/core/directive/type/common";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import {
  commonChangeDefaultDate,
  modalShowListener,
} from "@/core/directive/function/common";
import { useRouter } from "vue-router";
import { DebtorOption } from "@/core/directive/interface/debtor";
import { PaymentGateway } from "@/core/directive/type/transactionReport";

export default defineComponent({
  name: "settlement-cash-internal-bank-balance-add-prepayment",
  emits: ["update-list", "reset-form"],
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const router = useRouter();
    const {
      showServerErrorMsg,
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
    } = mixin();

    const loading = ref(false);

    const addPrepaymentRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref({
      paid_time: "",
      external_transaction_platform: "",
      paid_amount: 0,
      paid_currency: "CNY",
      allow_payments: "deposit",
      note: "",
      debtor_entity_id: "",
      pay_bank_account_no: "",
      pay_bank_account_name: "",
      opening_bank: "",
      external_transaction_id: "",
    });

    const options = ref({
      currency: [
        {
          label: "EUR",
          value: "EUR",
        },
        {
          label: "CNY",
          value: "CNY",
        },
      ],
      entityLoading: false,
      entityOptions: [] as DebtorOption[],
      debtor_name: "",
    });

    const rules = ref({
      external_transaction_platform: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      paid_time: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      paid_currency: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      paid_amount: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      external_transaction_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      debtor_entity_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      pay_bank_account_no: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      pay_bank_account_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      opening_bank: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const paymentMethodChange = (e) => {
      formData.value.pay_bank_account_no = "";
      formData.value.pay_bank_account_name = "";
      formData.value.opening_bank = "";
      formData.value.external_transaction_id = "";
    };

    const debtorChange = (e) => {
      if (e) {
        const findIndex = options.value.entityOptions.findIndex(
          (item) => item.id === e
        );
        options.value.debtor_name = options.value.entityOptions[findIndex].name;
      } else {
        options.value.debtor_name = "";
      }
    };

    const getEntityData = async (value) => {
      options.value.entityLoading = true;
      const { data } = await ApiDebtor.searchDebtor({
        value: value,
      });
      options.value.entityLoading = false;
      if (data.code == 0) {
        options.value.entityOptions = data.data;
      }
    };

    const debounceEntitySearch = _.debounce(getEntityData, 1000);

    const searchEntityItems = (query: string) => {
      debounceEntitySearch(query);
    };

    const submitValidate = (callback) => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          Swal.fire({
            text: t("settlementCash.submitTip"),
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: t("common.yesClose"),
            cancelButtonText: t("common.noReturn"),
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger",
            },
          }).then(async (result) => {
            if (result.isConfirmed) {
              loading.value = true;
              const params = {
                id: props.option.itemData.id,
                ...formData.value,
              };
              callback(params);
            }
          });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submit = () => {
      submitValidate(async (params) => {
        loading.value = true;
        const { data } = await ApiSettlementCash.internalBankAddPrepayment(
          params
        );
        loading.value = false;
        if (data.code === 0) {
          showFormSubmitSuccessMsg(() => {
            hideModal(addPrepaymentRef.value);
            emit("update-list");
          });
        } else {
          showServerErrorMsg(data);
        }
      });
    };

    const getFormInfo = async () => {
      // for (let key in formData.value) {
      //   if (props.option.itemData[key]) {
      //     formData.value[key] = props.option.itemData[key];
      //   }
      // }
      if (props.option.itemData.id !== 0) {
        formData.value.allow_payments = props.option.itemData.allow_payments;
        formData.value.paid_currency = props.option.itemData.currency;
        await getEntityData(props.option.itemData.account_id.toString());
        if (options.value.entityOptions.length > 0) {
          formData.value.debtor_entity_id = props.option.itemData.account_id;
          options.value.debtor_name = options.value.entityOptions[0].name;
        }
      }
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      options.value.debtor_name = "";
      options.value.entityOptions = [];
      emit("reset-form");
    };

    const handleDiscard = () => {
      hideModal(addPrepaymentRef.value);
    };

    onMounted(() => {
      modalShowListener(addPrepaymentRef.value, () => {
        setTimeout(() => {
          getFormInfo();
        }, 10);
      });
      modalHideListener(addPrepaymentRef.value, () => {
        resetForm();
      });
      //   getTaggingData();
    });

    return {
      t,
      commonChangeDefaultDate,
      PaymentGateway,
      props,
      addPrepaymentRef,
      formRef,
      loading,
      formData,
      rules,
      options,
      paymentMethodChange,
      debtorChange,
      searchEntityItems,
      handleDiscard,
      submit,
    };
  },
});
